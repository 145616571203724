.tabla{
border: 1px solid #ed7100;
border-radius: 15px;
overflow: hidden;
}

.columna span{
    display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.columna{
    overflow:hidden;
}

.columna:not(:last-child){
    border-right: 1px solid #ed7100;
}

.fila{ 
    margin-right: 0!important;
    margin-left: 0!important;
}


.fila:nth-child(odd) {
    background-color: #fddf7f;
    padding-left: 0;
    
  }
.btn-info{
    background-color: #ed7100!important;
    color: white!important;
    border: none!important;
}

@media (max-width: 766px) {
    .columna:not(:last-child){
        border-right: none;
    }
    
}