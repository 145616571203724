.fondo{
    background: url(../../assets/images/Fondo-Login.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100%;   
    /* height:100vh; */
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 30px;
}
.menu_dash{
    background: #323232 !important;
    color: #fff !important; 
    text-align: center !important;
    border-radius: 10px  !important;
    margin-bottom: 10px;
    cursor: pointer;
}

.menu_dash_activo{
    background: rgb(237, 113, 0) !important;
    color: #fff !important; 
    text-align: center !important;
    border-radius: 10px  !important;
    margin-bottom: 10px;
    border: 1px solid rgb(237, 113, 0);
    cursor: pointer;
}

.borde{
    border-right: 1px solid rgb(237, 113, 0);
}

.margen{
    margin-top: 150px;
}


@media(max-width: 767px){
    .borde{
        border-right: none;
    }
    .margen{
        margin-top: auto;
    }
}