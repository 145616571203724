@font-face {
  font-family: 'STIHL_title';
  src: url('./assets/fonts/STIHLContrafaceDisplayTitling.eot');
  src: url('./assets/fonts/STIHLContrafaceDisplayTitling.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/STIHLContrafaceDisplayTitling.woff2') format('woff2'),
      url('./assets/fonts/STIHLContrafaceDisplayTitling.woff') format('woff'),
      url('./assets/fonts/STIHLContrafaceDisplayTitling.ttf') format('truetype'),
      url('./assets/fonts/STIHLContrafaceDisplayTitling.svg#STIHLContrafaceDisplayTitling') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'STIHL_text';
  src: url('./assets/fonts/STIHLContrafaceText.eot');
  src: url('./assets/fonts/STIHLContrafaceText.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/STIHLContrafaceText.woff2') format('woff2'),
      url('./assets/fonts/STIHLContrafaceText.woff') format('woff'),
      url('./assets/fonts/STIHLContrafaceText.ttf') format('truetype'),
      url('./assets/fonts/STIHLContrafaceText.svg#STIHLContrafaceText') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'STIHL_text_ligth';
  src: url('./assets/fonts/STIHLContrafaceTextLight.eot');
  src: url('./assets/fonts/STIHLContrafaceTextLight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/STIHLContrafaceTextLight.woff2') format('woff2'),
      url('./assets/fonts/STIHLContrafaceTextLight.woff') format('woff'),
      url('./assets/fonts/STIHLContrafaceTextLight.ttf') format('truetype'),
      url('./assets/fonts/STIHLContrafaceTextLight.svg#STIHLContrafaceText') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding:0;
  font-family: STIHL_text, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.titulo_login {
  font-family: STIHL_title;
  color: #fff;
  text-align: center;
  margin: 2em 0;
}

.titulo {
  font-family: STIHL_title;
  color: #000;
  text-align: center;
}
.titulo2 {
  font-family: STIHL_title;
  color: #000;
}

.main {
  padding:40px
}
.MuiPaper-root{
  background: #fff !important;
}
.cartIcon {
  position: fixed;
  top: 10px;
  right: 140px;
  cursor:pointer;
  z-index: 100;
  background: yellow;
}
aside {
  width:460px;
  margin : 20px;
  overflow-x: hidden;
}
.hoja{
  position: absolute;
  right: 0;
  width: 150px;
}
@media(max-width: 767px){
  .hoja{
    position: absolute;
    right: 0;
    width: 100px;
  }
}
@media(max-width: 500px){
  aside{
    width: 100%;
    margin: 0px;
    padding: 30px;
  }
}
.itemCart {
  display:flex;
  justify-content: space-between;
}
.itemCart img{
  max-width: 100%;
  object-fit: cover;
  margin-left: 40px;
}

.itemInfo {
  display: flex;
  justify-content : space-between
}
.buttons {
  display:flex;
  justify-content : space-between
}

.whatsapp {
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  z-index:100;
}

.whatsapp-icon {
  margin-top:13px;
}