.bg-orange{
    background-color: #ed7100;    
}
.navbar-brand {
    color: white;
}
.nav-link {
    color: white!important;
}

.navbar-toggler{
    border: 1px solid #fff !important;
}

